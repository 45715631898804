import React from 'react'
import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image"
import { Layout } from "../components/layout"; 
import styled from 'styled-components';
import { title, wrapper, mainContent } from "./basic-page.module.css"

export default function  HirePage() {

  return (
    <Layout>

        <h1 className={title}>Hire Tomsnaps</h1>

        <div className={wrapper}>  
          <div className={mainContent}>
            <p>Need some photos? Why not hire tomsnaps?</p>

            <h2>Custom greetings & post cards</h2>
            <p>You can hire Tomsnaps for to create custom greetings cards/postcards from existing or commission new photos.</p>

            <h2>Headshots</h2>
            <p>You can hire Tomsnaps to take some headshot photos to use professionally or on social media.</p>

            <h2>Product photography</h2>
            <p>Do you sell your own products? And need some clean and clear photos of your products? Tomsnaps can help.</p>


            <h2>Need something else?</h2>
            <p>Do you have some other photography needs? Why not get in contact and Tomsnaps will discuss your requirements with you.</p>

            <p>You can contact Tomsnaps by emailing: <a href="mailto:tomsnapsuk@gmail.com">tomsnapsuk@gmail.com</a></p>

          </div>

        </div>  

    </Layout>
  )
}